import { round } from '@/helpers';
import { fieldInfo } from '@/models/EstimateFields';
import { DocumentReference, DocumentType } from './Document';
import EstimateComparison from './EstimateComparison';
import EstimateField from './EstimateField';
import EstimateItem from './EstimateItem';
import EstimateWorkflows from './EstimateWorkflows';
import EstimateWorkflowSummary from './EstimateWorkflowSummary';
import {
    getArrayOfObjectsFromDto as getArrayOfObjects,
    getDateTimeFromDto as getDate,
    getValueFromDto as getValue,
} from './_helper';

export default class Estimate {
    constructor(dto) {
        this.id = getValue(dto, 'id', 'number', 0);
        this.leadId = getValue(dto, 'leadId', 'number', null);
        this.name = getValue(dto, 'name', 'string', '');
        this.items = getArrayOfObjects(dto, 'items', EstimateItem);
        this.comparisons = getArrayOfObjects(dto, 'comparisons', EstimateComparison);
        this.workflowSummaries = {};
        this.modifiedTime = getDate(dto, 'modifiedTime', null);
        this.createdTime = getDate(dto, 'createdTime', null);
        this.documents = getArrayOfObjects(dto, 'documents', DocumentReference);
        this.workflowIds = getValue(dto, 'workflowIds', 'object', []);
        this.paymentOption = getValue(dto, 'paymentOption', 'number', 0);
        this.promotion = getValue(dto, 'promotion', 'number', 0);
        this.downpayment = getValue(dto, 'downpayment', 'number', 0);
        this.principal = getValue(dto, 'principal', 'number', 0);
        this.monthlyRate = getValue(dto, 'monthlyRate', 'number', 0);
        this.monthlyPayment = getValue(dto, 'monthlyPayment', 'number', 0);
        this.term = getValue(dto, 'term', 'object', {});
        this._itemTreeByParent = null;
        Object.defineProperty(this, '_itemTreeByParent', { enumerable: false });
        this._itemTreeByField = null;
        Object.defineProperty(this, '_itemTreeByField', { enumerable: false });

        if (typeof dto === 'object' && dto !== null && typeof dto.workflowSummaries === 'object') {
            for (const key of Object.keys(dto.workflowSummaries)) {
                this.workflowSummaries[key] = new EstimateWorkflowSummary(dto.workflowSummaries[key]);
            }
        }
    }

    get itemTreeByParent() {
        if (!this._itemTreeByParent) {
            this.refreshItemTree();
        }
        return this._itemTreeByParent;
    }

    get itemTreeByField() {
        if (!this._itemTreeByField) {
            this.refreshItemTree();
        }
        return this._itemTreeByField;
    }

    refreshItemTree() {
        const tree = {};
        const treeByField = {};
        for (const item of this.items) {
            (Array.isArray(tree[item.parentItemId]) ? tree[item.parentItemId] : (tree[item.parentItemId] = [])).push(
                item
            );
            (Array.isArray(treeByField[item.fieldId])
                ? treeByField[item.fieldId]
                : (treeByField[item.fieldId] = [])
            ).push(item);
        }
        this._itemTreeByParent = tree;
        this._itemTreeByField = treeByField;
    }

    clearItemTree() {
        this._itemTreeByParent = null;
        this._itemTreeByField = null;
    }

    get total() {
        let total = 0;
        for (const key in this.workflowSummaries) {
            if (Object.hasOwnProperty.call(this.workflowSummaries, key)) {
                total += this.workflowSummaries[key].total;
            }
        }
        return total;
    }

    get contracts() {
        return this.documents.filter((x) => x.type === DocumentType.contract);
    }

    get changeOrders() {
        return this.documents.filter((x) => x.type === DocumentType.changeOrder);
    }

    get purchaseOrders() {
        return this.documents.filter((x) => x.type === DocumentType.purchaseOrder);
    }

    get workOrders() {
        return this.documents.filter((x) => x.type === DocumentType.workOrder);
    }

    get costEstimates() {
        return this.documents.filter((x) => x.type === DocumentType.costEstimate);
    }

    get laborBills() {
        return this.documents.filter((x) => x.type === DocumentType.laborBill);
    }

    get finalLaborBills() {
        return this.documents.filter((x) => x.type === DocumentType.finalLaborBill);
    }

    get itemizedEstimates() {
        return this.documents.filter((x) => x.type === DocumentType.itemizedEstimate);
    }

    clone() {
        return new Estimate(JSON.parse(JSON.stringify(this)));
    }

    updateItems(items, fieldIds, fields, skipNormalize = false) {
        const groupedItems = items.reduce((obj, x) => {
            let array = obj[x.fieldId];
            if (!Array.isArray(array)) {
                array = obj[x.fieldId] = [];
            }
            array.push(x);
            return obj;
        }, {});
        fieldIds.forEach((x) => {
            const field = fields[x];
            if (field) {
                field.descendantIds.forEach((y) => {
                    if (!Array.isArray(groupedItems[y])) {
                        groupedItems[y] = [];
                    }
                });
            }
        });
        const workflowIds = new Set();
        // remove existing items with these fieldIds, and items with fieldIds that do not longer exist
        const itemFieldIds = new Set(Object.keys(groupedItems));
        const validFieldIds = new Set(Object.keys(fieldInfo).filter((x) => !itemFieldIds.has(x)));
        this.items = this.items.filter((x) => validFieldIds.has(x.fieldId));
        const permitFields = ['1.36', '2.43', '2.46', '2.841', '2.843', '2.845', '2.847'];
        for (const fieldId in groupedItems) {
            if (Object.hasOwnProperty.call(groupedItems, fieldId)) {
                const itemTemp = items.find((x) => x.fieldId === fieldId);
                const workflowId = EstimateField.getWorkflowId(fieldId);
                workflowIds.add(workflowId);
                // add new items
                const newItems = groupedItems[fieldId];
                for (let i = 0; i < newItems.length; i++) {
                    const item = newItems[i];
                    let quantity = item.quantity;

                    if (permitFields.includes(item.fieldId)) {
                        if (item.quantity <= 1) {
                            quantity = 1
                        }
                    }

                    if (
                        item instanceof EstimateItem &&
                        quantity > 0 &&
                        item.value !== null &&
                        item.value !== undefined
                    ) {
                        this.items.push(item);
                    }
                }
            }
        }
        if (!skipNormalize) {
            this.items.sort((a, b) => {
                if (a.id < 0 && b.id < 0) {
                    // sort negative IDs in descending order
                    return b.id - a.id;
                } else {
                    // sort positive IDs in ascending order
                    return a.id - b.id;
                }
            });
        }
        const itemTotals = {};
        workflowIds.forEach((x) => (itemTotals[x] = { nonDiscountableItemTotal: 0, discountableItemTotal: 0 }));
        for (let i = 0; i < this.items.length; i++) {
            const item = this.items[i];
            if (item.parentItemId !== null) continue;
            const field = fields[item.fieldId];
            const workflowId = field.workflowId;
            if (!workflowIds.has(workflowId)) {
                continue;
            } else if (field.excludeFromDiscount) {
                itemTotals[workflowId].nonDiscountableItemTotal += item.total;
            } else {
                itemTotals[workflowId].discountableItemTotal += item.total;
            }
        }
        for (const workflowId of workflowIds) {
            const total = itemTotals[workflowId];
            let summary = this.getWorkflowSummary(workflowId);
            summary.nonDiscountableItemTotal = round(total.nonDiscountableItemTotal, 2);
            summary.discountableItemTotal = round(total.discountableItemTotal, 2);
        }
        this.clearItemTree();
    }

    updateComparisons(workflowId, comparisons) {
        // remove existing comparisons with this workflowId
        for (let i = 0; i < this.comparisons.length; ) {
            if (this.comparisons[i].workflowId === workflowId) {
                this.comparisons.splice(i, 1);
            } else {
                i++;
            }
        }
        // add new comparisons
        for (let i = 0; i < comparisons.length; i++) {
            const comparison = comparisons[i];
            if (
                comparison instanceof EstimateComparison &&
                comparison.workflowId === workflowId &&
                comparison.fieldOptionId
            ) {
                this.comparisons.push(comparison);
            }
        }
    }

    updatePromotion(workflowId, promotion) {
        const summary = this.getWorkflowSummary(workflowId);
        summary.promotion = round(promotion, 2);
    }

    updateFee(workflowId, fee) {
        const summary = this.getWorkflowSummary(workflowId);
        summary.fee = round(fee, 2);
    }

    getWorkflowSummary(workflowId) {
        const workflowKey = EstimateWorkflows.getKey(workflowId);
        let summary = this.workflowSummaries[workflowKey];
        if (!(summary instanceof EstimateWorkflowSummary)) {
            summary = this.workflowSummaries[workflowKey] = new EstimateWorkflowSummary();
        }
        return summary;
    }

    getWorkflowSummaryByKey(workflowKey) {
        let summary = this.workflowSummaries[workflowKey];
        if (!(summary instanceof EstimateWorkflowSummary)) {
            summary = this.workflowSummaries[workflowKey] = new EstimateWorkflowSummary();
        }
        return summary;
    }

    updatePaymentOption(workflowIds, paymentOption, promotion, downpayment, principal, monthlyPayment, term) {
        this.workflowIds = workflowIds;
        this.paymentOption = paymentOption;
        this.promotion = promotion;
        this.downpayment = downpayment;
        this.principal = principal;
        this.monthlyPayment = monthlyPayment;
        this.term = term;
    }
}
