import { url as urlHelper } from '@/helpers';
import PaginatedList from '@/models/PaginatedList';
import FolderLog from '@/models/FolderLog';
import { fetchWrap, offlineResponse } from '../_helpers';

export default {
	/**
	 * Get all folderLogs
	 * @returns (async) Returns an array of folderLogs if the request was successful, otherwise a Response.
	 */
	async getAll(searchQuery = '', limit = 100, offset = 0) {
		const query = {
			searchQuery,
			limit,
			offset,
		};
		const url = urlHelper('/api/folderLog', query);
		let response;
		try {
			response = await fetchWrap(url);
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			const data = await response.json();
			const model = new PaginatedList(data);
			model.data = data.data.map(x => new FolderLog(x));

			return model;
		} else {
			return response;
		}
	},
};
