<template>
	<slot :items="items" :removeItem="removeItem" :addItem="addItem" :addItemAfter="addItemAfter" />
</template>

<script setup>
import { useFieldArray } from 'vee-validate';
import { inject, onMounted, toRef } from 'vue';

const props = defineProps({
	name: {
		type: String,
		required: true,
	},
})
const { remove: removeItem, push: pushItem, insert: insertItem, fields: items } = useFieldArray(toRef(props, 'name'));
const getNewFieldArrayItem = inject('getNewFieldArrayItem');
const modelLookup = inject('modelLookup');
const recommendedItems = inject('recommendedItems');
const model = modelLookup(props.name);

const addItem = () => {
	pushItem(getNewFieldArrayItem(props.name));
};
const addItemAfter = i => {
	insertItem(i + 1, getNewFieldArrayItem(props.name));
};

onMounted(() => {
	if (recommendedItems[model.field.id]) {
		const values = recommendedItems[model.field.id];
		const keys = Object.keys(recommendedItems[model.field.id]);

		keys.forEach((key, i) => {
			if (i > 0 && values[key] === 0 && items.value.length === keys.length) {
				removeItem(key)
			}
		});
	}
	
})

defineExpose({
	items,
	removeItem,
	addItem,
	addItemAfter,
});
</script>
