<template>
	<teleport to="body">
		<transition name="toggle-overlay">
			<div v-if="isOpen" class="screen-overlay" @click="closeDialog"></div>
		</transition>

		<transition name="toggle-dialog">
			<div v-if="isOpen" :class="[$route.meta.appName ? `dialog-${$route.meta.appName}` : '', { 'dialog-with-heading': $slots['header-content'] && $slots['body-content'] }]" class="dialog" v-bind="$attrs">
				<template v-if="$slots['header-content'] && $slots['body-content']">
					<div class="dialog-header flex">
						<slot name="header-content" />
						<button class="btn-link x-button" @click="closeDialog">
							X
						</button>
					</div>
					<div class="dialog-body" :class="paymentAlert ? 'payment-dialog-body' : ''">
						<slot name="body-content" />
						<div class="text-center mt-2" v-if="!useXButton">
							<button type="button" class="btn" :class="{
								'btn-outline-green': type === MessageType.success || type === MessageType.info,
								'btn-outline-red': type === MessageType.warning || type === MessageType.error,
							}" @click="closeDialog" ref="confirmButton">
								<span>{{ confirmText }}</span>
							</button>
						</div>
					</div>
				</template>
				<template v-else>
					<slot>
						<p class="mt-0 text-center semi-bold">{{ text }}</p>
					</slot>
					<div class="text-center mt-2">
						<button type="button" class="btn" :class="{
							'btn-outline-green': type === MessageType.success || type === MessageType.info,
							'btn-outline-red': type === MessageType.warning || type === MessageType.error,
						}" @click="closeDialog" ref="confirmButton">
							<span>{{ confirmText }}</span>
						</button>
					</div>
				</template>
			</div>
		</transition>
	</teleport>
</template>

<script setup>
import MessageType from '@/models/MessageType';
import { nextTick, ref } from 'vue';

const confirmButton = ref(null);
const isOpen = ref(false);
const type = ref(false);
const text = ref('');
const confirmText = ref('');
const resolvePromise = ref(null);

defineProps({
	paymentAlert: {
		type: Boolean,
		default: false,
	},
	useXButton: {
		type: Boolean,
		default: false,
	},
});

defineOptions({
	inheritAttrs: false
});

function openDialog(dialogText, options = {}) {
	resolve();
	text.value = dialogText;
	type.value = options.type ?? 'info';
	confirmText.value = options.confirmText ?? 'Confirm';
	isOpen.value = true;
	nextTick(() => {
		if (confirmButton.value) {
			confirmButton.value.focus();
		}
	});
	return new Promise((resolve) => {
		resolvePromise.value = resolve;
	});
}
function closeDialog() {
	isOpen.value = false;
	resolve();
}
function resolve(value) {
	if (resolvePromise.value !== null) {
		const resolve = resolvePromise.value;
		resolvePromise.value = null;
		resolve(value);
	}
}

defineExpose({
	openDialog,
	closeDialog,
});
</script>
