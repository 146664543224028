import { getValueFromDto as getValue, getDateTimeFromDto as getDate } from './_helper';

export default class FolderLog {
	constructor(dto) {
		this.folderType = getValue(dto, 'folderType', 'string', '');
		this.folderId = getValue(dto, 'folderId', 'string', {});
		this.projectType = getValue(dto, 'projectType', 'string', '');
		this.projectTypes = getValue(dto, 'projectTypes', 'string', '');
		this.isMappingCorrect = getValue(dto, 'isMappingCorrect', 'boolean', false);
		this.details = getValue(dto, 'details', 'string', '');
		this.isFixed = getValue(dto, 'isFixed', 'boolean', false);
		this.createdAt = getDate(dto, 'createdAt', null);
	}
}
